<template>
	<div class="my-account">
		<div class="header-menu va-row">
			<div class="va-row">
				<div class="flex container-ddh-menu xs12 with-bottom va-row">
					<div class="flex xs9 no-padding">
						<h1>Adoções</h1>
					</div>
					<div class="flex xs3 no-padding text-right cta-menu-area">
						<button v-on:click="showProject('post')" class="btn btn-change">
							ADICIONAR ADOÇÃO
						</button>
					</div>
				</div>
			</div>
			<div class="clearfix"></div>
		</div>

		<div class="container-dhh va-row">
			<div class="flex md12 xs12 no-padding">
				<h4></h4>
			</div>
			<div class="flex md12 xs12 no-padding">
				<div class="ui-grid">
					<div class="tab">
						<button @click="changePage('adoptions-manager')">
							Adoções Ativas
						</button>
						<button class="active" @click="changePage('adoptions-pending')">
							Adoções pendentes
						</button>
					</div>

					<div class="my-data-table va-row" id="cards">
						<div class="flex xs12 no-padding fiscal-notes">
							<div
								class="body-spacing"
								:class="list.length > 0 ? 'body-contains' : ''"
							>
								<div class="va-row empty-area" v-if="list.length === 0">
									<div class="empty-box">
										<div class="empty-box-header">ADOÇÕES</div>
										<div class="empty-box-body">
											<b>Nenhuma adoção cadastrada no momento.</b><br />
											As informações de adoções estarão disponíveis assim que as
											adoções estiverem cadastradas.
										</div>
									</div>
								</div>

								<div class="va-row" v-if="list.length > 0">
									<div class="sets-list va-row">
										<div class="va-row">
											<div class="flex md3 xs12 no-padding-left">
												<div class="input-group">
													<label>Status</label>
													<select-normal
														:items="listStatus"
														:value="''"
														label="Selecione um status"
														name="typeName"
														v-model="selectStatus"
														@change="onChangeFilterStatus($event)"
													/>
												</div>
											</div>

											<div class="flex md3 xs12">
												<div class="input-group">
													<label>Escolas</label>
													<select-normal
														:items="listSchool"
														:value="''"
														label="Selecione uma escola"
														name="typeName"
														v-model="selectSchool"
														@change="onChangeFilterSchools($event)"
													/>
												</div>
											</div>

											<div class="flex md3 xs12">
												<label>CONTRATO</label>
												<div class="input-group">
													<select-normal
														:items="listPaymentStatus"
														:value="''"
														label="Selecione um status"
														name="typeName"
														v-model="selectPaymentStatus"
														@change="onChangeFilterPaymentStatus($event)"
													/>
												</div>
											</div>

											<div class="flex md3 xs12 no-padding-right">
												<label>Tipo de contrato</label>
												<div class="input-group">
													<select-normal
														:items="listContracts"
														:value="''"
														label="Selecione um contrato"
														name="typeName"
														v-model="selectContracts"
														@change="onChangeFilterContracts($event)"
													/>
												</div>
											</div>
										</div>
									</div>

									<div
										class="sets-list table-list-simple va-row"
										v-if="list.length > 0"
									>
										<div class="va-row header no-padding">
											<div class="flex item main">ADOÇÃO PENDENTES</div>
											<div class="flex item dates">SELEÇÃO DE PRODUTO</div>
											<div class="flex item dates">CONTRATO</div>
											<div class="flex item dates">ENDEREÇO</div>
											<div class="flex item action">AÇÃO</div>
										</div>

										<div class="va-row simple-list-body">
											<div
												class="flex md12 no-padding line-item"
												v-for="item in list"
												:key="item.id"
											>
												<div class="va-row pendent lines">
													<div class="flex item main">
														<div class="va-row">
															<div class="flex xs12 text-left no-padding">
																{{ item.name.toLowerCase() }}
															</div>
														</div>
													</div>

													<div class="flex item dates">
														<div class="va-row">
															<div
																class="flex xs12 no-padding"
																:class="
																	item.step_1_status === true
																		? 'checked-item'
																		: 'non-checked'
																"
															>
																<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path
																		d="M7.84459 16.4619L12.0001 20.6174L24.7018 7.92861C25.4821 7.14826 26.7442 7.14826 27.5246 7.92861C28.3049 8.70896 28.3049 9.97107 27.5246 10.7514L13.4179 24.8581C12.6376 25.6384 11.3755 25.6384 10.5951 24.8581L5.02179 19.2847C4.24144 18.5044 4.24144 17.2423 5.02179 16.4619C5.80214 15.6816 7.06425 15.6816 7.84459 16.4619Z"
																		fill="#00CF72" />
																</svg>

															</div>
															<div class="flex xs12 no-padding-bottom">
																<span
																	class="created-data print"
																	v-if="item.step_1_status"
																	@click="openEdit(1, item.id, 'put')"
																	>Editar</span
																>
															</div>
														</div>
													</div>

													<div class="flex item dates">
														<div class="va-row">
															<div
																class="flex xs12 no-padding"
																:class="
																	item.step_1_status &&
																	item.step_2_status === true
																		? 'checked-item'
																		: 'non-checked'
																"
															>
																<!-- <i class="fa fa-check"></i> -->
																<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path
																		d="M7.84459 16.4619L12.0001 20.6174L24.7018 7.92861C25.4821 7.14826 26.7442 7.14826 27.5246 7.92861C28.3049 8.70896 28.3049 9.97107 27.5246 10.7514L13.4179 24.8581C12.6376 25.6384 11.3755 25.6384 10.5951 24.8581L5.02179 19.2847C4.24144 18.5044 4.24144 17.2423 5.02179 16.4619C5.80214 15.6816 7.06425 15.6816 7.84459 16.4619Z"
																		fill="#00CF72" />
																</svg>
															</div>
															<div class="flex xs12 no-padding-bottom">
																<span
																	class="created-data print"
																	v-if="
																		item.step_1_status && item.step_2_status
																	"
																	@click="openEdit(2, item.id, 'put')"
																	>Editar</span
																>
															</div>
														</div>
													</div>

													<div class="flex item dates">
														<div class="va-row">
															<div
																class="flex xs12 no-padding"
																:class="
																	item.step_1_status &&
																	item.step_2_status &&
																	item.step_3_status === true
																		? 'checked-item'
																		: 'non-checked'
																"
															>
																<!-- <i class="fa fa-check"></i> -->
																<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
																	<path
																		d="M7.84459 16.4619L12.0001 20.6174L24.7018 7.92861C25.4821 7.14826 26.7442 7.14826 27.5246 7.92861C28.3049 8.70896 28.3049 9.97107 27.5246 10.7514L13.4179 24.8581C12.6376 25.6384 11.3755 25.6384 10.5951 24.8581L5.02179 19.2847C4.24144 18.5044 4.24144 17.2423 5.02179 16.4619C5.80214 15.6816 7.06425 15.6816 7.84459 16.4619Z"
																		fill="#00CF72" />
																</svg>
															</div>
															<div class="flex xs12 no-padding-bottom">
																<span
																	class="created-data print"
																	v-if="
																		item.step_1_status &&
																		item.step_2_status &&
																		item.step_3_status
																	"
																	@click="openEdit(3, item.id, 'put')"
																	>Editar</span
																>
															</div>
														</div>
													</div>

													<div class="flex item action">
														<button
															class="btn btn-white cancel"
															v-if="
																item.step_1_status &&
																!item.step_2_status &&
																!item.step_3_status
															"
															@click="openEdit(2, item.id, 'post')"
														>
															CONTINUAR EDITANDO
														</button>
														<button
															class="btn btn-white cancel"
															v-if="
																item.step_1_status &&
																item.step_2_status &&
																!item.step_3_status
															"
															@click="openEdit(3, item.id, 'post')"
														>
															CONTINUAR EDITANDO
														</button>
														<button
															class="btn"
															v-else-if="
																item.step_1_status &&
																item.step_2_status &&
																item.step_3_status
															"
															@click="openModalConfirmation('active', item)"
														>
															ATIVAR ADOÇÃO
														</button>

														<button
															v-on:click.stop.prevent="
																openModalDelete('adoption_delete', item)
															"
															class="btn-change-trash"
														>
															<!-- <span class="fa fa-trash"></span> -->
															<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																<path
																	d="M7.25074 20.9993C6.86755 20.9993 6.53226 20.8493 6.24487 20.5493C5.95748 20.2493 5.81379 19.8993 5.81379 19.4993V5.24934H5.55034C5.34279 5.24934 5.17115 5.17851 5.03544 5.03684C4.89973 4.89518 4.83187 4.71601 4.83187 4.49934C4.83187 4.28268 4.89973 4.10351 5.03544 3.96184C5.17115 3.82018 5.34279 3.74934 5.55034 3.74934H9.33432C9.33432 3.53268 9.40217 3.35351 9.53788 3.21184C9.6736 3.07018 9.84523 2.99934 10.0528 2.99934H14.9384C15.146 2.99934 15.3176 3.07018 15.4533 3.21184C15.589 3.35351 15.6569 3.53268 15.6569 3.74934H19.4409C19.6484 3.74934 19.8201 3.82018 19.9558 3.96184C20.0915 4.10351 20.1593 4.28268 20.1593 4.49934C20.1593 4.71601 20.0915 4.89518 19.9558 5.03684C19.8201 5.17851 19.6484 5.24934 19.4409 5.24934H19.1774V19.4993C19.1774 19.8993 19.0337 20.2493 18.7463 20.5493C18.459 20.8493 18.1237 20.9993 17.7405 20.9993H7.25074ZM9.78935 16.5993C9.78935 16.816 9.85721 16.9952 9.99292 17.1368C10.1286 17.2785 10.3003 17.3493 10.5078 17.3493C10.7154 17.3493 10.887 17.2785 11.0227 17.1368C11.1584 16.9952 11.2263 16.816 11.2263 16.5993V8.12434C11.2263 7.90768 11.1584 7.72851 11.0227 7.58684C10.887 7.44518 10.7154 7.37434 10.5078 7.37434C10.3003 7.37434 10.1286 7.44518 9.99292 7.58684C9.85721 7.72851 9.78935 7.90768 9.78935 8.12434V16.5993ZM13.7649 16.5993C13.7649 16.816 13.8328 16.9952 13.9685 17.1368C14.1042 17.2785 14.2758 17.3493 14.4834 17.3493C14.6909 17.3493 14.8626 17.2785 14.9983 17.1368C15.134 16.9952 15.2019 16.816 15.2019 16.5993V8.12434C15.2019 7.90768 15.134 7.72851 14.9983 7.58684C14.8626 7.44518 14.6909 7.37434 14.4834 7.37434C14.2758 7.37434 14.1042 7.44518 13.9685 7.58684C13.8328 7.72851 13.7649 7.90768 13.7649 8.12434V16.5993Z"
																	fill="#4965BC" />
															</svg>

														</button>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<vuestic-modal
									:show.sync="show"
									v-bind:large="true"
									ref="largeModal"
									:okText="'modal.confirm' | translate"
									:cancelText="'modal.cancel' | translate"
									:noButtons="true"
								>
									<div
										slot="title"
										v-if="
											managerCredential.currentAdoption &&
											managerCredential.currentAdoption.length == 0
										"
									>
										Adicionar nova adoção
									</div>
									<div
										slot="title"
										v-if="
											managerCredential.currentAdoption &&
											managerCredential.currentAdoption.name
										"
									>
										{{ managerCredential.currentAdoption.name }}
									</div>

									<adoption-edit-manager></adoption-edit-manager>
								</vuestic-modal>

								<vuestic-modal
									:show.sync="show"
									v-bind:large="true"
									ref="optionModalModal"
									:okText="'modal.confirm' | translate"
									:cancelText="'modal.cancel' | translate"
									:noButtons="true"
									:cancelDisabled="true"
								>
									<div slot="title" v-if="typeModalconfirm === 'active'">
										Ativar adoção
									</div>

									{{ confirmMessage }}

									<br /><br />
									<hr />
									<br />

									<div class="va-row text-right">
										<div class="flex md12 text-right no-padding">
											<button
												class="btn btn-primary cancel"
												@click="closeModal()"
											>
												CANCELAR
											</button>
											<button
												class="btn right"
												v-if="typeModalconfirm === 'active'"
												@click="sendRelease(currentItemModal)"
											>
												ATIVAR
											</button>
										</div>
									</div>
								</vuestic-modal>

								<vuestic-modal
									:show.sync="show"
									v-bind:large="true"
									ref="miniModal"
									:okText="'modal.confirm' | translate"
									:cancelText="'modal.cancel' | translate"
									:noButtons="true"
									:cancelDisabled="true"
								>
									<div slot="title">Sucesso</div>

									{{ customResponse }}

									<br /><br />
									<hr />
									<br />
									<div class="va-row">
										<div class="flex md12 text-right no-padding">
											<button
												class="btn btn-white right cancel"
												@click="closeModal()"
											>
												OK, ENTENDI
											</button>
										</div>
									</div>
								</vuestic-modal>
							</div>
						</div>
					</div>
				</div>
			</div>

			<vuestic-modal
				:show.sync="show"
				v-bind:large="true"
				ref="optionModalModalExclude"
				:okText="'modal.confirm' | translate"
				:cancelText="'modal.cancel' | translate"
				:noButtons="true"
				:cancelDisabled="true"
			>
				<div slot="title" v-if="typeModalconfirm === 'adoption_delete'">
					Excluir adoção
				</div>

				{{ confirmMessage }}

				<br /><br />

				<!-- {{ errors }} -->

				<div class="error-label text-center" v-if="errors.items[0]">
					<!-- {{ errors }} -->

					{{ errors.items[0].msg }}
				</div>

				<hr />
				<br />

				<div class="va-row text-right">
					<div class="flex md12 text-right no-padding">
						<button class="btn btn-primary cancel" @click="closeModal()">
							CANCELAR
						</button>

						<button
							class="btn right"
							v-if="typeModalconfirm === 'adoption_delete'"
							@click="deleteAdoption(currentItemModal)"
						>
							EXCLUIR
						</button>
					</div>
				</div>
			</vuestic-modal>

			<!-- <infinite-loading
				v-if="filterLoaded"
				:identifier="[
					selectStatus,
					selectPayments,
					selectPaymentStatus,
					selectContracts,
				]"
				@infinite="getProjects"
			>
				<div slot="spinner"></div>
				<div slot="no-more"></div>
				<div slot="no-results"></div>
			</infinite-loading> -->
		</div>
	</div>
</template>

<script>
	import { mask } from "vue-the-mask";
	import { mapGetters } from "vuex";
	import Vue from "vue";
	window.Vue = Vue;

	import apiErrors from "services/apiErrors";
	const axios = require("axios");

	export default {
		name: "peoples",
		directives: { mask },
		computed: {
			...mapGetters({
				managerCredential: "managerCredential",
				// ...
			}),
		},

		data() {
			return {
				filters: [],

				selectSchool: "",

				page: 1,
				list: [],

				emptyPeople: {
					name: "",
					email: "",
					role_id: 20,
					username: "",
					client_id: 1,
					products: null,
					school_id: null,
					grade_id: [],
				},

				listStatus: [],
				listPayments: [],
				listPaymentStatus: [],
				listContracts: [],
				listSchool: [],

				filterLoaded: false,
				selectStatus: "",
				selectPayments: "",
				selectPaymentStatus: "",
				selectContracts: "",

				projectModalType: null,

				show: false,

				customResponse: "",

				typeModalconfirm: "",
				confirmMessage: "",
				currentItemModal: null,

				upload: false,
			};
		},

		mounted() {
			const self = this;

			self.getFilters();

			this.$root.$on("closeModal", () => {
				self.closeModal();
			});

			// CRUD SCHOOLS
			this.$root.$on("addProject", (project) => {
				//school.grades = []
				if (self.list.length > 1) {
					self.list.push(project);
				} else {
					self.page = 1;
					self.list = [];
				}
			});

			this.$root.$on("editProject", (project, step) => {
				this.getProjects();

				var result = self.list.filter((item) => {
					return item.id === project.id;
				});

				if (result.length > 0) {
					var index = self.list.indexOf(result[0]);

					if (step === 1) {
						self.list.splice(index, 1, project);
						self.closeModal();
					} else if (step === 2) {
						self.list[index].step_2_status = true;
					} else if (step === 3) {
						self.list[index].step_3_status = true;
					}
				}
			});

			this.getProjects();
		},

		methods: {
			openModalDelete(type, item) {
				const self = this;

				self.currentItemModal = item;

				self.$refs.optionModalModalExclude.open();
				self.typeModalconfirm = type;

				if (type === "nf") {
					self.confirmMessage =
						"Ao confirmar será enviado a nota fiscal para o responsável da escola";
				} else if (type === "print") {
					self.confirmMessage =
						"Você deseja liberar os livros para impressão? Lembre-se de certificar se o pagamento já foi realizado antes de confirmar o envio para produção.";
				} else if (type === "checkout") {
					self.confirmMessage =
						"Ao confirmar será enviado novamente o e-mail de cobrança para o responsável da escola";
				} else if (type === "adoption_delete") {
					self.confirmMessage =
						"Você tem certeza que deseja excluir esta adoção?";
				}
			},

			deleteAdoption(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				self.errors.items = [];

				axios
					.post(
						process.env.VUE_APP_ROOT_API + "/projects/delete/" + item.id,
						[],
						config
					)
					.then((response) => {
						//self.$router.push({ name: "adoptions-pending" });
						// self.page = 1;
						self.getProjects();
						self.closeModal();
					})
					.catch(function (error) {
						console.log(error);
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.errors.items.push(fieldError);
					});
			},

			getFilters() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				axios
					.get(process.env.VUE_APP_ROOT_API + "/projects/filters", config)
					.then((response) => {
						self.filters = response.data.data;

						self.listStatus = response.data.data.status.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						self.listPayments = response.data.data.payments.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						self.listPaymentStatus = response.data.data.payment_status.map(
							(s) => ({
								key: s.id,
								value: s.name,
							})
						);

						self.listContracts = response.data.data.contracts.map((s) => ({
							key: s.id,
							value: s.name,
						}));

						self.filterLoaded = true;
					})
					.catch((e) => {
						// this.errors.push(e)
						apiErrors.handle(e);
					});
			},

			changePage(url) {
				this.$router.push({ name: url });
			},

			openModalConfirmation(type, item) {
				const self = this;

				self.currentItemModal = item;
				self.$refs.optionModalModal.open();
				self.typeModalconfirm = type;
				self.confirmMessage =
					"Ao confirmar a escola será ativada: " + item.name;
			},

			sendCheckout(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				self.errors.items = [];

				axios
					.get(
						process.env.VUE_APP_ROOT_API + "/projects/send_checkout/" + item.id,
						config
					)
					.then((response) => {
						self.closeModal();
						self.customResponse = response.data.data.msg;
						self.$refs.miniModal.open();

						//alert("CHECKOUT");
						//self.list = response.data.data.projects
					})
					.catch(function (error) {
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
						apiErrors.handle(error);
					});
			},

			sendNF(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				self.errors.items = [];

				axios
					.get(
						process.env.VUE_APP_ROOT_API +
							"/projects/create_invoice/" +
							item.id,
						config
					)
					.then((response) => {
						//alert("NF");
						//self.list = response.data.data.projects
						self.closeModal();
						self.customResponse = response.data.data.msg;
						self.$refs.miniModal.open();
					})
					.catch(function (error) {
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
						apiErrors.handle(error);
					});
			},

			sendPrint(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				self.errors.items = [];

				axios
					.get(
						process.env.VUE_APP_ROOT_API + "/projects/release_print/" + item.id,
						config
					)
					.then((response) => {
						self.closeModal();
						self.customResponse = response.data.data.msg;
						self.$refs.miniModal.open();
					})
					.catch(function (error) {
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
						apiErrors.handle(error);
					});
			},

			sendRelease(item) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + self.managerCredential.token,
					},
				};

				axios
					.post(
						process.env.VUE_APP_ROOT_API + "/projects/release",
						{ project_id: item.id },
						config
					)
					.then(function (response) {
						self.$router.push({ name: "adoptions-manager" });
						self.checkBookLimit();
					})
					.catch(function (error) {
						let fieldError = {
							field: "error",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
						apiErrors.handle(error);
					});
			},

			checkBookLimit() {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + self.managerCredential.token,
					},
				};

				axios
					.get(process.env.VUE_APP_ROOT_API + "/check_book_limit", config)
					.then(function (response) {
						self.$store.commit(
							"UPDATE_BOOK_LIMIT",
							response.data.data.book_limit
						);
					})
					.catch(function (error) {
						console.log(response);
						let fieldError = {
							field: "error-modal",
							msg: error.response.data.message,
							rule: "error", // optional
						};
						self.$validator.errors.add(fieldError);
					});
			},

			openGrade(item) {
				const self = this;

				self.$store.commit("SAVE_MANAGER_CURRENT_ADOPTION", item);
				self.$router.push({ name: "grades-manager" });
			},

			closeModal() {
				if (this.$refs.largeModal) {
					this.$refs.largeModal.close();
				}

				if (this.$refs.gradeModal) {
					this.$refs.gradeModal.close();
				}

				if (this.$refs.miniModal) {
					this.$refs.miniModal.close();
				}

				if (this.$refs.optionModalModal) {
					this.$refs.optionModalModal.close();
				}

				if (this.$refs.optionModalModalExclude) {
					this.$refs.optionModalModalExclude.close();
				}

				// optionModalModalExclude;

				//this.show = false
			},

			openEdit(order, project_id, type) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				axios
					.get(
						process.env.VUE_APP_ROOT_API + "/projects/steps/" + project_id,
						config
					)
					.then((response) => {
						var project = response.data.data.project;
						project.type = type;
						project.step = order;
						project.id = project_id;

						if (order === 2 && !project.address) {
							project.cnpj = "";
							project.address = {
								district: "",
								address_number: "",
								state: "",
								city: "",
								address1: "",
								address2: "",
								zip_postal_code: "",
							};

							project.contact_name = "";
							project.contact_email = "";
							project.contact_phone = "";
							project.contract_method_id = "";
							project.payment_method_id = "";
							project.ie = "";
							project.razao_social = "";
							project.shipping_address = {
								district: "",
								address_number: "",
								state: "",
								city: "",
								address1: "",
								address2: "",
								zip_postal_code: "",
							};
						}

						if (order === 3 && !project.shipping_address) {
							project.shipping_address = {
								district: "",
								address_number: "",
								state: "",
								city: "",
								address1: "",
								address2: "",
								zip_postal_code: "",
							};
						}

						self.$store.commit("SAVE_MANAGER_CURRENT_ADOPTION", project);
						self.project_id = project.id;
						self.$refs.largeModal.open();
						//self.show = true
					})
					.catch((e) => {
						// self.errors.push(e)
						apiErrors.handle(e);
					});
			},

			showProject(type) {
				const self = this;

				self.projectModalType = type;
				self.$store.commit("CLEAR_MANAGER_CURRENT_ADOPTION");
				//self.setVuex()

				self.$refs.largeModal.open();
				//this.show = true
			},

			onChangeFilterStatus(event) {
				this.page = 1;
				this.list = [];
				this.selectStatus = event.target.value;
				this.getProjects();
			},

			onChangeFilterPayment(event) {
				this.page = 1;
				this.list = [];
				this.selectPayments = event.target.value;
				this.getProjects();
			},

			onChangeFilterPaymentStatus(event) {
				this.page = 1;
				this.list = [];
				this.selectPaymentStatus = event.target.value;
				this.getProjects();
			},

			onChangeFilterContracts(event) {
				this.page = 1;
				this.list = [];
				this.selectContracts = event.target.value;
				this.getProjects();
			},

			getProjects($state) {
				const self = this;

				let config = {
					headers: {
						Authorization: "Bearer " + this.managerCredential.token,
					},
				};

				self.page = 1;
				self.list = [];

				let url =
					"/projects/pending?page=" +
					self.page +
					"&limit=25&status=" +
					self.selectStatus +
					"&payment_status=" +
					self.selectPaymentStatus +
					"&payments=" +
					self.selectPayments +
					"&contracts=" +
					self.selectContracts;

				axios
					.get(process.env.VUE_APP_ROOT_API + url, config)
					.then((response) => {
						if (response.data.data.projects.length) {
							response.data.data.projects.forEach((element) => {
								self.list.push(element);

								//if (list.length === 0) {
								var school = {
									key: element.id,
									value: element.name,
								};
								self.listSchool.push(school);
							});
							self.page += 1;

							$state.loaded();
						} else {
							$state.complete();
						}

						//self.list = response.data.data.projects
					})
					.catch((e) => {
						// this.errors.push(e)
						apiErrors.handle(e);
					});
			},
		},
	};
</script>
<style lang="scss" scoped>
.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}

	.btn-white {
		color: #4965bc;
	}
	.created-data {
		font-size: 12px;
		display: block;
		color: #afa2ba;

		&.init {
			margin-bottom: 10px;
		}

		&.nf {
			cursor: pointer;
			color: #4965bc;

			&:hover {
				text-decoration: underline;
			}
		}

		&.print {
			cursor: pointer;
			color: #4965bc;
			width: 100%;
			text-decoration: underline;
			&:hover {
				text-decoration: underline;
			}
		}
	}
	.btn-clean {
		background-color: #fff;
		color: #4965bc;
		text-decoration: none;
		background-color: transparent;
		border: 0px;
		padding: 0px;
		font-size: 16px;
		font-weight: 300;
		font-family: "Roboto", sans-serif;
		letter-spacing: 0px;
		text-align: left;

		&:hover {
			text-decoration: underline;
		}
	}

	.table-list {
		border: solid 1px #dfdae4;
		border-radius: 15px;
		overflow: hidden;
		margin-top: 15px !important;
		width: 100%;
	}

	.button-actions {
		.btn {
			padding: 10px;
			border-radius: 0px;
			font-size: 10px;

			&.btn-blue {
				background-color: rgb(74, 144, 226);
				max-width: 192px;
			}
		}
	}

	.pendent {
		background-color: #fff;
		// span{
		//   color:gray;

		// }

		.active {
			color: green;
			font-weight: bold;
		}
	}
	.btn-blue {
		// background-color:rgb(74, 144, 226);
		max-width: 192px;
	}

	.sets-list {
		label {
			font-size: 12px;
		}

		// padding: 24px;
		// padding-bottom: 0px;

		::v-deep .select {
			font-size: 14px !important;
		}
	}

	.schedule-list {
		text-align: center;

		.bullet {
			display: flex;
			width: 110px;
			height: 110px;
			border-radius: 60px;
			margin: 0 auto;
			text-align: center;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;

			&.blue {
				background-color: rgb(74, 144, 226);
				color: #fff;
			}

			&.purple {
				background-color: rgb(116, 106, 208);
				color: #fff;
			}

			&.purple-green {
				background-color: rgb(0, 207, 113);
				color: #fff;
			}
		}

		.blocks {
			padding: 10px 15px;
			margin-bottom: 15px;

			&.blue {
				background-color: rgb(74, 144, 226);
				color: #fff;
			}

			&.purple {
				background-color: rgb(116, 106, 208);
				color: #fff;
			}

			&.purple-green {
				background-color: rgb(0, 207, 113);
				color: #fff;
			}
		}
	}
	.btn-login {
		background-color: transparent;
		border: 0px;
		color: #4965bc;
	}
	.hide {
		display: none;
	}
	@media (max-width: 580px) {
		.btn-primary {
			width: 100%;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 5px;
			}
		}
	}

	// .fiscal-notes ::v-deep .form-group:last-child {
	// 	margin-bottom: 64px !important;
	// }

	.error-label {
		color: red;
		text-align: left;
		margin-bottom: 10px;
	}
	small {
		font-family: Roboto;
		font-size: 12px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: normal;
		letter-spacing: normal;
		color: #000000;
		text-indent: 15px;
		margin-top: -5px;
		display: block;
		margin-bottom: 10px;
		// padding-left: 15px;
	}
	h5 {
		font-family: "Nunito";
		font-size: 20px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
	}
	.address-info {
		margin-bottom: 15px;
	}
	.editing {
		margin-top: 15px;
	}
	.radio-button-wrapper {
		padding: 14px 11px;
		margin-top: 10px;

		@media (max-width: 990px) {
			padding: 20px 19px;
			margin-top: 10px;
		}
	}
	.header-menu-club {
		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #68596e;
			margin-top: 34px;
			margin-bottom: 34px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.add-new-card {
		border-radius: 10px;
		border: dashed 2px #2cd3d8;
		background-color: #ffffff;
		font-family: Nunito;
		font-size: 12px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 3.5;
		letter-spacing: 0.7px;
		text-align: center;
		color: #4965bc;
		margin-bottom: 20px;
		padding: 15px 0px;
		cursor: pointer;

		.plus {
			font-family: "Nunito";
			font-size: 35px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: 0.7px;
			text-align: center;
			color: #ffffff;
			width: 40px;
			height: 40px;
			transform: rotate(-180deg);
			border-radius: 40px;
			border: solid 1px #4965bc;
			background-color: #4965bc;
			display: block;
			margin: 0 auto;
			margin-top: 20px;
			line-height: 36px;
		}
	}

	// Default of page

	h4 {
		font-family: "Nunito";
		font-size: 24px;
		font-weight: 900;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.17;
		letter-spacing: 1.3px;
		color: #000000;
		margin-bottom: 0;
	}
	.header-menu {
		background-color: white;

		h1 {
			font-family: "Nunito";
			font-size: 40px;
			font-weight: 900;
			font-style: normal;
			font-stretch: normal;
			line-height: 0.7;
			letter-spacing: normal;
			color: #68596e;
			margin-top: 34px;
			margin-bottom: 34px;

			@media (max-width: 990px) {
				font-size: 18px;
			}
		}
	}
	.my-data {
		/* Facebook bar */
		.facebook-tag {
			height: 65px;
			border-radius: 5px;
			background-color: #3a5998;
			color: white;
			padding: 14px 20px;
			margin-bottom: 20px;

			svg {
				font-size: 26px;
				margin-right: 0px;
				float: left;

				path {
					fill: #fff;
				}
			}

			.name {
				display: inline-block;
				margin-top: 1px;
				line-height: 37px;
			}

			.facebook-toggle {
				float: right;
				display: block;
				margin-top: 5px;
			}
		}
	}

	.change-password {
		border-radius: 10px;
		border: solid 2px #f0ebf5;
		background-color: #ffffff;
		padding: 24px 20px;
		position: relative;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 7px;
			font-family: "Roboto";

			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
		}

		h5 {
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #afa2ba;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.floated-icon {
			float: left;
			width: 36px;
			height: 36px;
			font-size: 30px;

			svg {
				position: relative;
				top: -5px;
				left: -3px;

				path {
					fill: #afa2ba;
				}
			}
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}
	}

	.my-data-table {
		h4 {
			margin-top: 50px;
			margin-bottom: 20px;
		}
	}
	.fiscal-notes {
		// border-radius: 0px 0px 10px 10px;
		// border: solid 1px #f0ebf5;
		// background-color: #ffffff;
		// position: relative;
		// border: solid 2px #f0ebf5;
		// border-top: 0px;
		// margin-bottom: 20px;
		border-radius: 0px 0px 16px 16px !important;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		.body-spacing {
			padding: 0px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}

			.btn-change-edit {
				border: none;
				color: #4965bc;
				background: transparent;
			}

			//  div:last-child{
			//   margin-bottom: 0px;
			// }
		}

		hr {
			border-top: solid 1px #f0ebf5;
			margin-top: 0px;
			margin-bottom: 0px;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		.company-code {
			text-transform: capitalize;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		// .panel:last-child{
		//   margin-bottom: 0px;
		// }
	}

	.address-boxes {
		border-radius: 10px;
		border: solid 1px #f0ebf5;
		background-color: #ffffff;
		position: relative;
		border: solid 2px #f0ebf5;

		h4 {
			font-size: 16px;
			color: #000000;
			margin-top: 0px;
			margin-bottom: 10px;
			font-family: "Roboto";
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			line-height: normal;
			letter-spacing: normal;
		}

		svg {
			path {
				fill: #afa2ba;
			}
		}

		.clube {
			svg {
				path {
					fill: #fff;
				}
			}
		}

		.body-spacing {
			padding: 30px 30px;

			@media (max-width: 990px) {
				padding: 25px 15px 15px 15px;
			}
		}

		hr {
			border-top: solid 2px #f0ebf5;
		}

		.dual-inputs {
			padding: 0px 8px;
			margin-bottom: 20px;
		}

		.new-password-group {
			margin-top: 20px;
			display: block;
		}

		.editor {
			right: 18px;
			top: 20%;
		}

		::v-deep .form-group {
			margin-bottom: 20px;
			padding: 22px 20px;

			::v-deep &:last-child {
				margin-bottom: 20px;
			}
		}

		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}

		.address-row {
			padding: 16px 0px;
			position: relative;
			border-bottom: solid 1px #f0ebf5;
			b {
				font-size: 16px;
				color: #000000;
			}
			:last-child {
				border-bottom: none;
			}
		}
	}

	.address-row {
		position: relative;
	}

	.more-cards {
		.more-address {
			border-radius: 10px;
			border: solid 1px #f0ebf5;
			background-color: #ffffff;
		}
	}

	.panel {
		// height: 200px;
		border-radius: 10px;
		border: solid 2px #9378f0;
		background-color: #ffffff;
		overflow: hidden;
		margin-bottom: 20px;

		.header-panel {
			padding: 0px 20px;
			background-color: #9378f0;
			line-height: 33px;
			font-size: 16px;
			font-weight: bold;
			font-style: normal;
			font-stretch: normal;
			letter-spacing: normal;
			color: #ffffff;
		}

		.clube img {
			margin-top: -5px;
		}

		b {
			font-size: 16px;
			color: #000000;
		}

		.panel-body {
			position: relative;
			padding: 10px 0px;
			font-family: Roboto;
			font-size: 14px;
			font-weight: normal;
			font-style: normal;
			font-stretch: normal;
			line-height: 1.5;
			letter-spacing: normal;
			color: #afa2ba;
			margin: 0px !important;

			.flex.xs1 {
				-ms-flex-preferred-size: 8.33333%;
				flex-basis: 6.33333%;
				-webkit-box-flex: 0;
				-ms-flex-positive: 0;
				flex-grow: 0;
				max-width: 6.33333%;
			}

			b {
				font-family: Roboto;
				font-size: 16px;
				font-weight: normal;
				font-style: normal;
				font-stretch: normal;
				line-height: 1.31;
				letter-spacing: normal;
				color: #000000;
			}

			.editor {
				right: 18px;
				top: 20%;
			}
		}
	}

	.subscription-box {
		// padding: 30px !important;

		font-family: Roboto;
		font-size: 16px;
		font-weight: normal;
		font-style: normal;
		font-stretch: normal;
		line-height: 1.31;
		letter-spacing: normal;
		color: #000000;

		.toggled {
			margin-top: 10px;
		}
	}
	.newsletter {
		// margin-top:40px;
		padding: 31px;
		border: solid 2px #f0ebf5;

		@media (max-width: 990px) {
			margin-top: 0px;
			padding: 15px;
		}
	}

	// Custom Component
	.v-switch-button {
		::v-deep &:before {
			content: "";
			display: block;
			position: relative;
			background-color: #fff;
			width: 6px;
			height: 6px;
			border-radius: 14.5px;
			margin-left: auto;
			margin-right: auto;
			top: 9px;
		}
	}

	.vue-js-switch {
		&.toggled {
			.v-switch-button {
				::v-deep &:before {
					content: "";
					display: block;
					position: relative;
					background-color: #7ed321;
					width: 6px;
					height: 6px;
					border-radius: 14.5px;
					margin-left: auto;
					margin-right: auto;
					top: 9px;
				}
			}
		}
	}

	.name-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}
	.lastname-box {
		@media (max-width: 980px) {
			padding: 0px !important;
		}
	}

	::v-deep .vuestic-modal {
		width: 50px;
		.modal-dialog {
			max-width: 720px !important;
		}
	}

	.empty-area {
		padding: 33px 31px;

		.empty-box {
			width: 100%;
			border: 1px solid #dfdae4;
			border-radius: 16px;
			overflow: hidden;

			&-header {
				padding: 22px;
				background: #f4f2fb;
				border-bottom: 1px solid #dfdae4;

				font-family: "Roboto";
				font-style: normal;
				font-weight: 700;
				font-size: 12px;
				line-height: 14px;
				/* identical to box height */

				display: flex;
				align-items: center;
				text-transform: uppercase;

				color: #68596e;
			}

			&-body {
				font-family: "Roboto";
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 21px;
				text-align: center;
				color: #68596e;
				padding: 24px;
			}
		}
	}

	.cta-menu-area {
		align-items: center;
		display: flex;
		flex-wrap: wrap-reverse;
	}

	.body-contains {
		padding: 32px !important;
	}

	.simple-list-body .line-item:last-child {
		.lines {
			border-bottom: none !important;
		}
	}

	.table-list-simple {
		.header {
			.item {
				&.dates {
					line-height: 1.25;
				}
			}
		}
		.item {
			&.main {
				width: 34%;
				text-transform: capitalize;
			}

			&.paid {
				color: #00cf72;
			}

			&.dates {
				display: flex;
				width: 13.3% !important;
				align-items: center;
				text-align: center;
				justify-content: center;
			}

			&.action {
				width: 26.1%;
			}

			.link {
				// border-bottom: 1px solid #4965bc;
				color: #4965bc;
				text-decoration: underline;
				font-size: 14px;
			}

			.product-title {
				text-transform: capitalize;
			}
		}

		.lines {
			border-bottom: 1px solid #dfdae4 !important;

			// &:last-child {
			// 	border-bottom: none;
			// }
			// &:last-child {
			// 	background-color: pink !important;
			// }
			.item {
				border-right: 1px solid #dfdae4;
				padding: 24px;

				&:last-child {
					border-right: none;
				}
			}
		}

		// .simple-list-body .flex .lines {
		// 	// border-
		// 	&:last-child {
		// 		.item {
		// 			border-right: none !important;
		// 		}
		// 	}
		// }
	}

	.btn-change-trash {
		border: none;
		color: #4965bc;
		border: 1px solid #4965bc;
		border-radius: 50px;
		width: 39px;
		height: 39px;
		background: transparent;
		max-width: 39px;
		margin-left: 14px;

		span {
			font-size: 16px;
		}

		&:hover {
			background: rgba(255, 70, 65, 0.1);
			color: #ff4641;
			border: 1px solid #ff4641;

			svg {
				path {
					fill: #ff4641;
				}
			}
		}
	}
</style>
